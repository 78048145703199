import * as React from "react";
import * as style from "./styles/error404.module.scss";
import { graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { motion } from "framer-motion";
import PageWrapper from "components/PageWrapper";
import SecretCircle from "components/SecretCircle";
import HeaderSection from "components/HeaderSection";
import MutableCursorArea from "components/MutableCursorArea";
import TravoltaImg from "images/secret_circles/travolta.gif";

const zeroVariants = {
  closed: {
    scale: 1,
  },
  open: {
    scale: 3,
  },
};

const zeroImgVariants = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
  },
};

const Title = () => {
  return (
    <div className={style.Title}>
      <div>4</div>
      <SecretCircle
        className={style.Title_zero}
        variants={zeroVariants}
        openVariant="open"
        closedVariant="closed"
      >
        <motion.img
          variants={zeroImgVariants}
          src={TravoltaImg}
          alt=""
          className={style.Title_secretImg}
        />
      </SecretCircle>
      <div>4</div>
    </div>
  );
};

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper
      seoTitle={t("error404.seo.title")}
      seoDescription={t("error404.seo.description")}
    >
      <HeaderSection
        className={style.Content404}
        title={<Title />}
        description={
          <div className={style.Header404_description}>
            <p>{t("error404.description")}</p>
            <p className={style.Header404_descriptionLink}>
              <MutableCursorArea Tag={Link} to="/">
                {t("error404.goHome")}
              </MutableCursorArea>
            </p>
          </div>
        }
      />
    </PageWrapper>
  );
};

export default NotFoundPage;

export const data = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
