import * as React from "react";
import { useState } from "react";
import * as style from "./secretCircle.module.scss";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { desktopBreakpoint, notDesktopBreakpoint } from "../Mediaqueries";

const SecretCircle = ({
  className,
  variants,
  openVariant,
  closedVariant,
  children,
  ...other
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isDesktop = useMediaQuery(desktopBreakpoint);
  const isNotDesktop = useMediaQuery(notDesktopBreakpoint);
  return (
    <motion.div
      className={`${style.SecretCircle} ${className ? className : ""}`.trim()}
      variants={variants}
      initial={closedVariant}
      animate={isOpen ? openVariant : closedVariant}
      transition={{ type: "spring", stiffness: 100 }}
      whileHover={isDesktop ? openVariant : null}
      onClick={isNotDesktop ? () => setIsOpen((isOpen) => !isOpen) : null}
      {...other}
    >
      {children}
    </motion.div>
  );
};

export default SecretCircle;
